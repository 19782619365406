import React, { useEffect, useState } from "react";
import APIService from "../../@mock-api/api.service";
import { useSnackbar } from "notistack";
import { withRouter } from "next/router";
import {
  EMAIL_MASKING,
  ERROR_MESSAGE,
  EventEmitter,
  SUCCESS_MESSAGE,
} from "../../helper";
import * as Yup from "yup";
import { useFormik } from "formik";
import { images, slickSettingsVertical } from "../../../constant";
import { withSnackbar } from "notistack";
import { connect } from "react-redux";
import { useRouter } from "next/router";
import {
  Unlock,
  Lock,
  Sms,
  Eye,
  EyeSlash,
  Maximize,
  User,
  PasswordCheck,
} from "iconsax-react";
import { useDispatch, useSelector } from "react-redux";
import { set_UserDetails } from "../../redux/authAction/authAction";
import { set_children_details } from "../../redux/getChildrenAction/getChildrenAction";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Card, Col, Form, Modal, Row } from "react-bootstrap";
import Link from "next/link";
import SliderView from "./register/SliderView";
import LeftFromSection from "./LeftFromSection";
import { isMobile, isTablet, isBrowser, osName, browserName, browserVersion,osVersion } from 'react-device-detect';
const apiService = new APIService();
const preloginBaseUrl = process.env.NEXT_PUBLIC_PRE_LOGIN_ENDPOINT;

const newLogin = () => {
  const slickSettingsVertical = {
    vertical: false,
    centerMode: false,
    slidesToShow: 1,
    dots: true,
    arrows: false,
    swipe: true,
    autoplay: true,
    autoPlaySpeed: 2500,
    infinite: true,
    loop: false,
    swipeToSlide: true,
    pauseOnHover: true,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          centerMode: false,
          vertical: false,
          slidesToShow: 1,
          arrows: false,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const userDetails = useSelector((state) => state);
  const dispatch = useDispatch();
  const router = useRouter();
  const { enqueueSnackbar } = useSnackbar();
  const [passwordShown, setPasswordShown] = useState(false);
  const [isLoginBtnDisabled, setIsLoginBtnDisabled] = useState(false);
  const [loginBtnText, setLoginBtnText] = useState("Login");
  const [otpBtnText, setLoginOtpText] = useState("Send OTP");
  const [showPassword, setShowPassword] = useState(true);
  const [showPassword1, setShowPassword1] = useState(true);
  const [showPassword2, setShowPassword2] = useState(true);

  const [tauser, settauser] = useState(null);
  const [otp, setOtp] = useState({
    first: null,
    second: null,
    third: null,
    forth: null,
  });
  const [confirmPassword, setConfirmPassword] = useState({
    newPassword: null,
    confirmPassword: null,
  });
  const [form1, setForm1] = useState(true);
  const [form2, setForm2] = useState(false);
  const [form3, setForm3] = useState(false);
  const [form4, setForm4] = useState(false);
  const [form5, setForm5] = useState(false);
  const [registereduserpopop, setregistereduserpopop] = useState(false);
  const [isLoginWithOTP, setIsLoginWithOTP] = useState(false);

  const firstRef = React.createRef();
  const secondRef = React.createRef();
  const thirdRef = React.createRef();
  const forthRef = React.createRef();

  var CryptoJS = require("crypto-js");
  var utf8 = require("utf8");

  const validationSchema = Yup.object().shape({
    email: form2
      ? Yup.string().required("Please enter email address")
      : Yup.string().required(
          "At least one of the fields email/phone is required"
        ),
    password: isLoginWithOTP
      ? null
      : Yup.string()
          .min(6, "Must be at least 6 chars!")
          .required("Password is required"),
    otp: isLoginWithOTP ? Yup.number().required("OTP is required") : null,
  });
  const initialValues = { email: "", password: "", otp: "" };

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  const togglePasswordConfirm = (e, type) => {
    e.preventDefault();
    type == 1
      ? setShowPassword1(!showPassword1)
      : setShowPassword2(!showPassword2);
  };

  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const validateOnlyNumbers = (phone) => {
    let mobileNumber = phone.replace("+", "");
    return mobileNumber.match(/^\d+$/);
  };

  //login submit
  const onSubmit = (values) => {
    setIsLoginBtnDisabled(true);
    setLoginBtnText("Please wait...");
    let data = {};
    if (validateOnlyNumbers(values.email.trim())) {
      data = {
        email: "",
        phone: values.email.trim().replace("+", ""),
        password: isLoginWithOTP ? null : values.password,
        otp: isLoginWithOTP ? values.otp : null,
        device_type: isMobile ? 'Mobile' : isTablet ? 'Tablet' : 'Desktop',
        operating_system: osName,
        browser_name: browserName,
        browser_version: browserVersion,
        OS_version: osVersion
      };
    } else if (validateEmail(values.email.trim())) {
      data = {
        phone: "",
        email: values.email.trim(),
        user_name: values.email.trim(),
        password: isLoginWithOTP ? null : values.password,
        otp: isLoginWithOTP ? values.otp : null,
        device_type: isMobile ? 'Mobile' : isTablet ? 'Tablet' : 'Desktop',
        operating_system: osName,
        browser_name: browserName,
        browser_version: browserVersion,
        OS_version: osVersion
      };
    } 
    // else if (!validateEmail(values.email.trim()) && !isLoginWithOTP) {
    //   setLoginBtnText("Login");
    //   setIsLoginBtnDisabled(false);
    //   enqueueSnackbar("Enter valid email address", ERROR_MESSAGE);
    //   return;
    // } 
    else {
      data = {
        phone: "",
        // email: values.email.trim(),
        user_name: values.email.trim(),
        password: isLoginWithOTP ? null : values.password,
        otp: isLoginWithOTP ? values.otp : null,
        device_type: isMobile ? 'Mobile' : isTablet ? 'Tablet' : 'Desktop',
        operating_system: osName,
        browser_name: browserName,
        browser_version: browserVersion,
        OS_version: osVersion
      };
    }

    (isLoginWithOTP ? apiService.loginViaOtp(data) : apiService.login(data))
    .then((res) => {
      if (res.data.data && 
          (isLoginWithOTP ? res.data.data.verifyLoginOTP : res.data.data.login)) {
          
          const responseData = isLoginWithOTP ? res.data.data.verifyLoginOTP : res.data.data.login;
  
          if (responseData?.login?.accessToken) {
              console.log("accessToken", responseData.login.accessToken);
          }
  
          localStorage.setItem("aark_auth", responseData.token);
          localStorage.setItem("userDetails", JSON.stringify(responseData));
          if (res.data.data && res.data.data?.login?.accessToken) {
              let encryptData = res.data.data?.login?.accessToken;
              let decData = CryptoJS.enc.Base64.parse(encryptData).toString(CryptoJS.enc.Utf8);
              let bytes = CryptoJS.AES.decrypt(decData, process.env.NEXT_PUBLIC_JWT_SECRET).toString(CryptoJS.enc.Utf8);
              console.log("333333333333",bytes)
              if (bytes) {
                  localStorage.setItem("assigned_teacher", bytes);
              }
          }
  
          setLoginBtnText("Redirecting...");
          setIsLoginBtnDisabled(false);
          dispatch(set_UserDetails(res.data.data));
          localStorage.removeItem("selected_child");
          localStorage.removeItem("child");
          localStorage.removeItem("childId");
  
          getChildren(responseData.id);
      } else {
          setLoginBtnText("Login");
          setIsLoginBtnDisabled(false);
          enqueueSnackbar(res.data.errors[0].message, ERROR_MESSAGE);
      }
  })
  .catch((errors) => {
      setIsLoginBtnDisabled(false);
      setLoginBtnText("Login");
  });
  
  };

  const getChildren = async (getChildrenId, getChildrenType) => {
  
    const userData = await localStorage.getItem("redirection_path");
    const privatePath = await localStorage.getItem("private_path");
    // const privatePath = await localStorage.getItem("private_path");
    apiService.getChildByParent({ getChildrenId }).then((success) => {
      if (success?.data?.data?.getChildren?.length > 0) {
        dispatch(set_children_details(success?.data?.data?.getChildren));
        handleRedirection(
          success?.data?.data?.getChildren,
          userData,
          privatePath,
          getChildrenId
        );
      } else {
        router.push(
          {
            pathname: "/child/add",
            query: {
              data: "",
              tda: getChildrenType,
            },
          },
          "/child/add"
        );
      }
    });
  };

  const handleRedirection = async (
    childrenArr,
    userData,
    privatePath,
    getChildrenId
  ) => {
    const child = await localStorage.getItem("child");
    const teacherAssigned = localStorage.getItem("assigned_teacher");
    let existingChildId = childrenArr[0]?.id;
    localStorage.setItem("childFirstName", JSON.stringify(childrenArr[0]?.first_name))
    if (child) {
      existingChildId = JSON.parse(child).id;
    }
    if (privatePath) {
      localStorage.setItem("selected_child", existingChildId);
      localStorage.setItem("child", JSON.stringify(childrenArr[0]));
      var childDetails=childrenArr[0]
      if (privatePath.includes("/calendar")) {
        router.push(privatePath);
      } else if (
        privatePath.includes("/report") &&
        privatePath.includes("?modal=")
      ) {
        router.push(privatePath);
      } else if (
        privatePath.includes("/report") ||
        privatePath.includes("/child")
      ) {
        let privateChild = privatePath.split("/");
        if (privateChild.length > 2) {
          let validChild = childrenArr?.find(
            (val) => val.id == privateChild[2]
          );
          if (validChild) {
            localStorage.setItem("selected_child", validChild.id);
            localStorage.setItem("child", JSON.stringify(validChild));
            router.push(privatePath);
          } else {
            if(childDetails.school_standard<3&&childDetails.grade12_child)
            {
             router.push(`/joonifyreports`);
            }
            else
            {
              router.push(`/joonifyreports`);
            }
          }
        } else {
          if(childDetails.school_standard<3&&childDetails.grade12_child)
            {
             router.push(`/joonifyreports`);
            }
            else
            {
              router.push(`/joonifyreports`);
            }
        }
      } else {
        if (!privatePath.includes("/teacher-assignment")) {
          router.push(privatePath);
        } else {
          if(childDetails.school_standard<3&&childDetails.grade12_child)
          {
           router.push(`/joonifyreports`);
          }
          else
          {
            router.push(`/joonifyreports`);
          }
        }
      }
      localStorage.removeItem("private_path");
      localStorage.removeItem("redirection_path");
    } else if (userData && JSON.parse(userData).userId == getChildrenId) {
      localStorage.setItem("selected_child", existingChildId);
      localStorage.setItem("child", JSON.stringify(childrenArr[0]));
      var childDetails=childrenArr[0]
      if (teacherAssigned) {
        // router.push(`/child/${existingChildId}`);
        if(childDetails.school_standard<3&&childDetails.grade12_child)
        {
         router.push(`/joonifyreports`);
        }
        else
        {
          router.push(`/joonifyreports`);
        }
      } else {
        if (JSON.parse(userData)?.path?.includes("/child/")) {
          let childId = JSON.parse(userData)?.path.replace("/child/", "");
          if (childId) {
            localStorage.setItem("selected_child", childId);
            localStorage.setItem(
              "child",
              JSON.stringify(childrenArr?.find((val) => val?.id == childId))
            );
            var childDetails=childrenArr?.find((val) => val?.id == childId)
            if(childDetails.school_standard<3&&childDetails.grade12_child)
            {
             router.push(`/joonifyreports`);
            }
            else
            {
              router.push(`/joonifyreports`);
            }
          } else {
            router.push(`/joonifyreports`);
          }
        } else {
          router.push(`/joonifyreports`);
        }
      }
      localStorage.removeItem("redirection_path");
    } else {
      var childDetails=childrenArr[0]
      if(childDetails.school_standard<3&&childDetails.grade12_child)
      {
       router.push(`/joonifyreports`);
      }
      else
      {
        router.push(`/joonifyreports`);
      }
      localStorage.removeItem("redirection_path");
    }
  };

  //forgot password get otp
  const forgotPasswordOTP = (email) => {
    setIsLoginBtnDisabled(true);
    setLoginOtpText("Please wait...");
    apiService
      .forgotPasswordOTP({
        email: email,
      })
      .then((success) => {
        if (success?.data?.data?.forgetPassword) {
          setOtp({ first: null, second: null, third: null, forth: null });
          handleShowForm(2);
          setIsLoginBtnDisabled(false);
          setLoginOtpText("Send OTP");
        } else {
          enqueueSnackbar(success.data.errors[0].message, ERROR_MESSAGE);
          setIsLoginBtnDisabled(false);
          setLoginOtpText("Send OTP");
        }
      })
      .catch((errors) => {
        setIsLoginBtnDisabled(false);
        setLoginOtpText("Send OTP");
      });
  };

  // verify otp
  const verifyForgotEmail = (data) => {
    setIsLoginBtnDisabled(true);
    apiService
      .verifyForgotEmail(data)
      .then((success) => {
        if (success?.data?.data?.verifyForgotEmail) {
          handleShowForm(3);
          setIsLoginBtnDisabled(false);
        } else {
          enqueueSnackbar(success.data.errors[0].message, ERROR_MESSAGE);
          setIsLoginBtnDisabled(false);
        }
      })
      .catch((errors) => {
        setIsLoginBtnDisabled(false);
      });
  };

  // setting password
  const resetPassword = (data) => {
    setIsLoginBtnDisabled(true);
    apiService
      .resetPassword({ data: data })
      .then((success) => {
        if (success?.data?.data?.resetPassword) {
          //Socket IO
          // const analytics = new Analytics();
          // analytics.updateForgotPasswordData(
          //   success?.data?.data?.resetPassword
          // );

          handleShowForm(4);
          setIsLoginBtnDisabled(false);
          setOtp({ first: null, second: null, third: null, forth: null });
          enqueueSnackbar("Password updated successfully ", SUCCESS_MESSAGE);
        } else {
          enqueueSnackbar(success.data.errors[0].message, ERROR_MESSAGE);
          setIsLoginBtnDisabled(false);
        }
      })
      .catch((errors) => {
        setIsLoginBtnDisabled(false);
      });
  };

  useEffect(() => {
    if (localStorage.getItem("aark_auth")) {
      const user = localStorage.getItem("userDetails");
      const parent = JSON.parse(user)?.id;
      getChildren(parent);
   
    }
  }, []);

  useEffect(() => {
    if (router.query.accestoken && router.query.parentid) {
      setIsLoginBtnDisabled(true);
      setLoginBtnText("Please wait...");
      let devicetype = isMobile ? 'Mobile' : isTablet ? 'Tablet' : 'Desktop'

      const data = {
        token: router.query.accestoken,
        id: router.query.parentid,
        admin: router.query.admin,
        device_type: devicetype,
        operating_system: osName,
        browser_name: browserName,
        browser_version: browserVersion,
        OS_version: osVersion
      };

      apiService
        .loginByToken(data)
        .then((res) => {
          if (res.data.data && res.data.data.loginByToken) {
            localStorage.setItem(
              "aark_auth",
              res.data.data?.loginByToken.authToken
            );
            localStorage.setItem(
              "userDetails",
              JSON.stringify(res.data.data.loginByToken)
            );
            let tdatype = res.data.data.loginByToken.type;
            setLoginBtnText("Redirecting...");
            setIsLoginBtnDisabled(false);
            dispatch(set_UserDetails(res.data.data));
            settauser(res.data.data.loginByToken.type);
    
            localStorage.removeItem("selected_child");
            localStorage.removeItem("child");
            localStorage.removeItem("childId");
            getChildren(
              res.data.data.loginByToken.id,
              res.data.data.loginByToken.type
            );
          } else {
            setLoginBtnText("Login");
            setIsLoginBtnDisabled(false);
            enqueueSnackbar(res.data.errors[0].message, ERROR_MESSAGE);
          }
        })
        .catch((errors) => {
          console.log(errors);
          setIsLoginBtnDisabled(false);
          setLoginBtnText("Login");
        });
    } else if (router.query.alreadyexist && router.query.value) {
      // enqueueSnackbar("It seems like you are already a registered user. No need to register again, as you already have an existing account with us. Please use your existing credentials to log in and access our services.", ERROR_MESSAGE);
      values.email = router.query.value;

      setregistereduserpopop(true);
    }
  }, [router.query]);

  const formik = useFormik({ initialValues, validationSchema, onSubmit });
  const { handleSubmit, handleChange, values, touched, errors, setErrors } =
    formik;

  // OTP handle and submit
  const handleOTPInput = (e) => {
    let otpData = { ...otp };
    otpData[e.target.name] = e.target.value;
    setOtp(otpData);
  };

  const handleSendOTP = (event) => {
    event.preventDefault();
    if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(values.email)) {
      enqueueSnackbar("Enter valid email", ERROR_MESSAGE);
      return false;
    }
    forgotPasswordOTP(values.email);
  };

  // validate and submit OTP
  const handleSubmitOTP = () => {
    event.preventDefault();
    if (!otp.first || !otp.second || !otp.third || !otp.forth) {
      enqueueSnackbar("Enter OTP", ERROR_MESSAGE);
      return false;
    }
    const data = {
      email: values.email,
      otp: parseInt(otp.first + otp.second + otp.third + otp.forth),
    };
    verifyForgotEmail(data);
  };

  // handling  new password confirm password
  const handleConfirmPassword = (e) => {
    let confirmData = { ...confirmPassword };
    confirmData[e.target.name] = e.target.value;
    setConfirmPassword(confirmData);
  };

  // submitting  and validating    new password confirm password
  const handleSubmitConfirmPassword = (event) => {
    event.preventDefault();
    if (!confirmPassword.newPassword || !confirmPassword.confirmPassword) {
      enqueueSnackbar("Enter both fields", ERROR_MESSAGE);
      return false;
    }
    if (confirmPassword.newPassword !== confirmPassword.confirmPassword) {
      enqueueSnackbar(
        "New password and Confirm password should match  ",
        ERROR_MESSAGE
      );
      return false;
    }

    const data = {
      email: values.email,
      otp: parseInt(otp.first + otp.second + otp.third + otp.forth),
      password: confirmPassword.newPassword,
      confirmPassword: confirmPassword.confirmPassword,
    };
    resetPassword(data);
  };

  // On filling OTP to jump to next field
  const autoTab = (e) => {
    const BACKSPACE_KEY = 8;
    const DELETE_KEY = 46;
    let tabindex = e.target.tabIndex || 0;
    tabindex = Number(tabindex);
    if (e.keyCode === BACKSPACE_KEY) {
      tabindex -= 1;
    } else if (e.keyCode !== DELETE_KEY) {
      tabindex += 1;
    }
    if (tabindex < 4 && tabindex > -1) {
      document.getElementById("number" + tabindex).focus();
    }
  };

  //Showing forms
  const handleShowForm = (type) => {
    if (type == 1) {
      setForm1(false);
      setForm2(true);
      setErrors({ ...errors, email: "" });
    }
    if (type == 2) {
      setForm2(false);
      setForm3(true);
    }
    if (type == 3) {
      setForm3(false);
      setForm4(true);
    }
    if (type == 4) {
      setForm4(false);
      setForm5(true);
    }
    if (type == 5) {
      document.getElementById("ResetForm2").reset();
      document.getElementById("ResetForm3").reset();
      document.getElementById("ResetForm4").reset();

      setOtp({ first: null, second: null, third: null, forth: null });
      setForm5(false);
      setForm1(true);
    }
  };

  const handleLoginViaToggle = (toggle) => {
    setIsLoginWithOTP(toggle);
    let event = {
      target: {
        name: "email",
        value: "",
      },
    };
    handleChange(event);
  };

  const generateLoginOtp = () => {
    let data = {};
    if (validateOnlyNumbers(values.email)) {
      data = {
        email: "",
        phone: values.email.replace("+", ""),
      };
    } else if (validateEmail(values.email)) {
      data = {
        phone: "",
        email: values.email,
      };
    } else {
      data = {
        phone: "",
        email: values.email,
      };
    }
    apiService.generateLoginOtp(data).then((res) => {
      if (res.data.data && res.data.data.generateLoginOTP) {
        enqueueSnackbar("An OTP is sent to provided details", SUCCESS_MESSAGE);
      } else {
        if (res.data?.errors && res.data?.errors[0].message) {
          enqueueSnackbar(res.data?.errors[0].message, ERROR_MESSAGE);
        }
      }
    });
  };

  function handleClick() {
    setregistereduserpopop(false);
    localStorage.removeItem("private_path");
  }

  return (
    <>
      <div className="loginmaindiv">
        <div className="row loginmaindivrow">
          <div className="col-lg-5 order-1 order-lg-1 ">
            <div className="forms-get-in">
              <div className="card card-getin">
                <div className=" content-getin">
                  <div className="newlogodiv">
                    <img className="newlogo " src={images.logo.default.src} />
                  </div>
                  <div
                    className={` form-anim form-1 ${
                      form1 ? "active-form" : ""
                    }`}
                  >
                    <div className=" card-head text-center">
                      <div className="loginheadingdiv">
                        <h2 className="loginheading">
                          Welcome to <span>Joonify</span>
                        </h2>
                      </div>
                    </div>
                    <div className="card-body">
                      <form id="loginForm" onSubmit={handleSubmit}>
                        <div className="row">
                          <div className=" form-group switch-custom ">
                            <div className=" switch-area switch-custom-shadow">
                              <input
                                id="toggle-on"
                                className="toggle toggle-left"
                                name="toggle"
                                value="false"
                                type="radio"
                                onChange={() => handleLoginViaToggle(false)}
                                checked={!isLoginWithOTP}
                              />
                              <label
                                htmlFor="toggle-on"
                                className={`btn-switch ${
                                  isLoginWithOTP ? "switchbtnnewlogin" : ""
                                }`}
                              >
                                <span>Login via Email / User Name</span>
                              </label>
                              <input
                                id="toggle-off"
                                className="toggle toggle-right"
                                name="toggle"
                                value="true"
                                type="radio"
                                onChange={() => handleLoginViaToggle(true)}
                                checked={isLoginWithOTP}
                              />
                              <label
                                htmlFor="toggle-off"
                                className={`btn-switch ${
                                  !isLoginWithOTP ? "switchbtnnewlogin" : ""
                                }`}
                              >
                                <span>Login via OTP</span>
                              </label>
                            </div>
                          </div>
                          <div className="col-md-12 form-group registeredlogindiv">
                            <User className="icon-field" variant="linear" />
                            <label className="username-text-label">Registered Email/ User Name / Mobile Number<span className="mandarysign">*</span></label>
                            <Form.Control
                              className="form-control form-control-shadow "
                              placeholder="Enter Registered Email or Mobile Number"
                              autoComplete="off"
                              name="email"
                              type="text"
                              id={"email"}
                              value={values.email}
                              onChange={(val) => {
                                let event = {
                                  target: {
                                    name: "email",
                                    value: val.target.value
                                      ?.replace(/ /g, "")
                                      .trim(),
                                  },
                                };
                                handleChange(event);
                              }}
                            />
                            {errors.email && touched.email ? (
                              <Form.Control.Feedback type="invalid">
                                {errors.email}
                              </Form.Control.Feedback>
                            ) : (
                              ""
                            )}
                          </div>
                          {!isLoginWithOTP ? (
                            <div className="col-md-12 form-group mb-1">
                              <div className="row">
                                <div className="col-md-12 mb-1">
                                  <PasswordCheck
                                    className="icon-field"
                                    variant="linear"
                                  />
                                  <label>Password<span className="mandarysign">*</span></label>
                                  <Form.Control
                                    className="form-control-shadow"
                                    placeholder="Enter Password"
                                    autoComplete="off"
                                    id={"password"}
                                    name="password"
                                    type={showPassword ? "password" : "text"}
                                    value={values.password}
                                    onChange={handleChange}
                                  />
                                  {errors.password && touched.password ? (
                                    <Form.Control.Feedback type="invalid">
                                      {errors.password}
                                    </Form.Control.Feedback>
                                  ) : (
                                    ""
                                  )}
                                  <span onClick={togglePassword}>
                                    {!showPassword ? (
                                      <EyeSlash
                                        className="icon-field icon-float"
                                        variant="linear"
                                      />
                                    ) : (
                                      <Eye
                                        className="icon-field icon-float"
                                        variant="linear"
                                      />
                                    )}
                                  </span>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className="col-md-12 form-group suffix-group mb-1">
                              <div className="row">
                                <div className="col-md-12 mb-1">
                                  <Lock
                                    className="icon-field"
                                    variant="linear"
                                  />
                                  <label>OTP<span className="mandarysign">*</span></label>
                                  <Form.Control
                                    className="form-control-shadow"
                                    placeholder="Enter OTP"
                                    autoComplete="off"
                                    id={"otp"}
                                    name="otp"
                                    onKeyPress={(event) => {
                                      if (!/[0-9]/g.test(event.key) || event.target.value.length >= 4) {
                                        event.preventDefault();
                                      }
                                    }}
                                    type={"number"}
                                    value={values.otp}
                                    onChange={handleChange}
                                    maxlength="4"
                                  />
                                  {errors.otp && touched.otp ? (
                                    <Form.Control.Feedback type="invalid">
                                      {errors.otp}
                                    </Form.Control.Feedback>
                                  ) : (
                                    ""
                                  )}

                                  <span className="suffix text-end text-decoration-underline">
                                    <a
                                      href=""
                                      className="link"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        generateLoginOtp(values);
                                      }}
                                    >
                                      Get OTP
                                    </a>
                                  </span>
                                </div>
                              </div>
                            </div>
                          )}
                          <h5 className="w-100 mb-0 text-end">
                            <a
                              className="link fw-regular text-decoration-underline"
                              onClick={() => handleShowForm(1)}
                            >
                              Forgot password?
                            </a>
                          </h5>

                          <div className="col-md-12 form-group text-center action-section loginBtnTextnewdiv mb-3 pt-3">
                            <button
                              type="submit"
                              className="btn btn-lg btn-pink w-auto mt-0 loginntnloginnew"
                              disabled={isLoginBtnDisabled}
                            >
                              {loginBtnText}
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                    <div className="col-12 card-foot card-foot-new">
                      <h5 className="text-center mb-0 regidtetextnewlogin">
                        Don’t have an account?&nbsp;
                        <a
                          target={"_blank"}
                          href={
                            process.env.NEXT_PUBLIC_PRE_LOGIN_ENDPOINT +
                            "/free-talent-assessment"
                          }
                          className="text-decoration-underline text-blue"
                        >
                          Register here
                        </a>
                      </h5>
                    </div>
                  </div>

                  <div
                    className={`col-12 form-anim form-2 ${
                      form2 ? "active-form" : ""
                    }`}
                  >
                    <div className="col-12 card-head text-center">
                      <div className="text-area">
                        <h3 className="text-blue">Forgot your password?</h3>
                        <h5 className="mb-0">
                          Please enter your registered Email id to receive a
                          verification code to reset your password.
                        </h5>
                      </div>
                    </div>
                    <div className="col-12 card-body">
                      <form id="ResetForm1">
                        <div className="row">
                          <div className="col-md-12 form-group">
                            <Sms className="icon-field" variant="linear" />
                            <label>Registered Email</label>
                            <Form.Control
                              className="form-control form-control-shadow"
                              placeholder="Enter Registered Email"
                              autoComplete="off"
                              name="email"
                              type="email"
                              value={values.email}
                              onChange={handleChange}
                            />
                            {errors.email ? (
                              <Form.Control.Feedback type="invalid">
                                {errors.email}
                              </Form.Control.Feedback>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="col-md-12 text-center action-section">
                            <button
                              type="submit"
                              className="btn btn-lg btn-pink w-auto"
                              disabled={isLoginBtnDisabled}
                              onClick={(event) => handleSendOTP(event)}
                            >
                              {otpBtnText}
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>

                  <div
                    className={`col-12 form-anim form-3 ${
                      form3 ? "active-form" : ""
                    } form-otp`}
                  >
                    <div className="col-12 card-head text-center">
                      <div className="text-area">
                        <h3 className="text-blue">Authenticate your account</h3>
                        <h5 className="">
                          Please enter the 4-digit OTP sent to your registered
                          mail id <b>{EMAIL_MASKING(values.email)}</b>
                        </h5>
                      </div>
                    </div>
                    <div className="col-12 card-body">
                      <form id="ResetForm2">
                        <div className="row">
                          <div className="col-md-12 form-group">
                            <Form.Control
                              className="form-control"
                              name="first"
                              id="number0"
                              type="tel"
                              ref={firstRef}
                              min="0"
                              max="9"
                              onKeyPress={(event) => {
                                if (!/[0-9]/g.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              onKeyUp={(e) => autoTab(e)}
                              onChange={(e) => handleOTPInput(e)}
                              autoFocus
                              tabIndex={0}
                              maxLength="1"
                              value={otp.first}
                            />
                            <Form.Control
                              className="form-control"
                              value={otp.second}
                              name="second"
                              type="tel"
                              id="number1"
                              ref={secondRef}
                              min="0"
                              max="9"
                              onKeyPress={(event) => {
                                if (!/[0-9]/g.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              onChange={(e) => handleOTPInput(e)}
                              onKeyUp={(e) => autoTab(e)}
                              autoFocus
                              tabIndex={1}
                              maxLength="1"
                            />
                            <Form.Control
                              className="form-control"
                              value={otp.third}
                              autoComplete="off"
                              name="third"
                              type="tel"
                              id="number2"
                              onChange={(e) => handleOTPInput(e)}
                              ref={thirdRef}
                              min="0"
                              max="9"
                              onKeyPress={(event) => {
                                if (!/[0-9]/g.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              onKeyUp={(e) => autoTab(e)}
                              autoFocus
                              tabIndex={2}
                              maxLength="1"
                            />
                            <Form.Control
                              className="form-control"
                              value={otp.forth}
                              autoComplete="off"
                              name="forth"
                              type="tel"
                              id="number3"
                              onChange={(e) => handleOTPInput(e)}
                              ref={forthRef}
                              min="0"
                              max="9"
                              onKeyPress={(event) => {
                                if (!/[0-9]/g.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              onKeyUp={(e) => autoTab(e)}
                              autoFocus
                              tabIndex={3}
                              maxLength="1"
                            />
                          </div>
                          <div className="col-md-12 text-center action-section">
                            <button
                              type="submit"
                              className="btn btn-lg btn-blue w-auto mx-2"
                              disabled={isLoginBtnDisabled}
                              onClick={() => handleSubmitOTP()}
                            >
                              {"Verify"}
                            </button>
                            <button
                              type="submit"
                              className="btn btn-lg btn-border w-auto mx-2"
                              // disabled={isLoginBtnDisabled}
                              onClick={(event) => {
                                event.preventDefault();
                                forgotPasswordOTP(values.email);
                              }}
                            >
                              {"Resend"}
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>

                  <div
                    className={`col-12 form-anim form-4 ${
                      form4 ? "active-form" : ""
                    }`}
                  >
                    <div className="col-12 card-head text-center">
                      <div className="text-area">
                        <h3 className="text-blue">Enter new password</h3>
                        <h5 className="mb-0">Please enter the new password.</h5>
                      </div>
                    </div>
                    <div className="col-12 card-body">
                      <form id="ResetForm3">
                        <div className="row">
                          <div className="col-md-12 form-group">
                            <Lock className="icon-field" variant="linear" />
                            <label>New password</label>
                            <Form.Control
                              className="form-control"
                              placeholder="Enter New Password"
                              name="newPassword"
                              type={showPassword1 ? "password" : "text"}
                              onChange={(e) => handleConfirmPassword(e)}
                            />
                            <span onClick={(e) => togglePasswordConfirm(e, 1)}>
                              {showPassword1 ? (
                                <EyeSlash
                                  className="icon-field icon-float"
                                  variant="linear"
                                />
                              ) : (
                                <Eye
                                  className="icon-field icon-float"
                                  variant="linear"
                                />
                              )}
                            </span>
                          </div>
                          <div className="col-md-12 form-group">
                            <Lock className="icon-field" variant="linear" />
                            <label>Confirm New Password</label>
                            <Form.Control
                              className="form-control"
                              placeholder="Enter Confirm New Password"
                              name="confirmPassword"
                              type={showPassword2 ? "password" : "text"}
                              onChange={(e) => handleConfirmPassword(e)}
                            />
                            <span onClick={(e) => togglePasswordConfirm(e, 2)}>
                              {showPassword2 ? (
                                <EyeSlash
                                  className="icon-field icon-float"
                                  variant="linear"
                                />
                              ) : (
                                <Eye
                                  className="icon-field icon-float"
                                  variant="linear"
                                />
                              )}
                            </span>
                          </div>
                          <div className="col-md-12 text-center action-section mt-4">
                            <button
                              type="submit"
                              className="btn btn-lg btn-blue w-auto"
                              disabled={isLoginBtnDisabled}
                              onClick={(e) => handleSubmitConfirmPassword(e)}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>

                  <div
                    className={`col-12 form-anim form-5 ${
                      form5 ? "active-form" : ""
                    }`}
                  >
                    <div className="col-12 card-head text-center">
                      <div className="text-area">
                        <h3 className="text-blue mb-0">
                          You have successfully changed your password.
                        </h3>
                      </div>
                    </div>
                    <div className="col-12 card-body">
                      <form id="ResetForm4">
                        <div className="row">
                          <div className="col-md-12 text-center action-section">
                            <button
                              className="btn btn-lg btn-blue w-auto"
                              disabled={isLoginBtnDisabled}
                              onClick={(e) => {
                                e.preventDefault();
                                handleShowForm(5);
                              }}
                            >
                              Go back to Login
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-7 order-2 order-lg-2  ">
            <div className="newloginslider">
              <div className="col-12  newloginslickcarsoselcontenthome">
                <Slider {...slickSettingsVertical}>
                  <div className="slidernewlogin1">
                    <img
                      className="slidernewloginimg"
                      src={images.slider1.default.src}
                    />
                    <div className="slidernewlogintext">
                      <h3 className="newloginsliderhead">
                        Discover your child’s hidden talents
                      </h3>
                      <p className="newloginsliderpara">
                        Our assessments uncover your child’s talents,
                        personality traits,
                        <br className="newloginsliderparabr" /> interests, and
                        learning requirements in just a few minutes.
                      </p>
                    </div>
                  </div>

                  <div className="slidernewlogin1">
                    <img src={images.slider2.default.src} />
                    <div className="slidernewlogintext">
                      <h3 className="newloginsliderhead">
                        Parenting made easier
                      </h3>
                      <p className="newloginsliderpara">
                        Receive a comprehensive report with valuable insights on
                        your child,
                        <br className="newloginsliderparabr" /> helping you make
                        informed decisions for their early learning journey.
                      </p>
                    </div>
                  </div>

                  <div className="slidernewlogin1">
                    <img src={images.slider3.default.src} />
                    <div className="slidernewlogintext">
                      <h3 className="newloginsliderhead">
                        Personalized guidance for your child’s growth 
                      </h3>
                      <p className="newloginsliderpara">
                        Our assessments uncover your child’s talents,
                        personality traits,
                        <br className="newloginsliderparabr" /> interests, and
                        learning requirements in just a few minutes.
                      </p>
                    </div>
                  </div>
                  <div className="slidernewlogin1">
                    <img src={images.slider4.default.src} />
                    <div className="slidernewlogintext">
                      <h3 className="newloginsliderhead">
                        Access to a world of learning opportunities
                      </h3>
                      <p className="newloginsliderpara">
                        Gain access to India’s top online courses curated to
                        <br className="newloginsliderparabr" /> develop your
                        child's talents and interests.
                      </p>
                    </div>
                  </div>
                  <div className="slidernewlogin1">
                    <img src={images.slider5.default.src} />
                    <div className="slidernewlogintext">
                      <h3 className="newloginsliderhead">
                        Track your child’s growth with ease
                      </h3>
                      <p className="newloginsliderpara">
                        Access a centralized dashboard to monitor your child's
                        <br className="newloginsliderparabr" /> scores,
                        progress, and milestones, making parenting a breeze.
                      </p>
                    </div>
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </div>

        <Modal
          show={registereduserpopop}
          // show={true}
          id="settings"
          aria-labelledby="settings"
          tabIndex="-1"
          dialogClassName={"modal-dialog-centered"}
          backdrop={"static"}
          size="ab"
        >
          <div className="talentadvisorygifmaindiv">
            <div className="talentadvisorygifdiv">
              <img
                className="talentadvisorygifreguser"
                src={images.thumbsupalreadyregistered.default.src}
              />

              <img
                className="talentpopupcrossregistered"
                src={images.talentpopupcross.default.src}
                onClick={() => handleClick()}
              />
            </div>

            <div>
              <div>
                <p className="congratalentpopup alreadyregisteridepopuppad">
                  It seems like you are already a{" "}
                  <br className="registereduserbr" />
                  registered user.
                </p>
              </div>
              <div>
                <p className="congratalentpopup alreadyregisteridepopuppad">
                  No need to register again, as you
                  <br className="registereduserbr" /> already have an existing
                  account
                  <br className="registereduserbr" /> with us.
                </p>
              </div>

              <div>
                <p className="congratalentpopup alreadyregisteridepopuppink alreadyregisteridepopuppad">
                  Please use your existing credentials
                  <br className="registereduserbr" /> to log in and access our
                  services.
                </p>
              </div>
            </div>

            <div className="paralentpopupfreeassessmentdiv">
              <button
                onClick={() =>
                  router.push(
                    {
                      pathname: "/",
                    },
                    handleClick()
                  )
                }
                className="paralentpopupfreeassessment"
              >
                Log In Here
              </button>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};


function mapStateToProps(state) {
  const {set_children_details } = state;
  return { set_children_details};
  }

const Login = withSnackbar(newLogin);
export default connect(mapStateToProps)(withRouter(Login));

// export default newLogin;
